import React from "react";
import AccountBanner from "../../components/AccountBanner"
import RedirectFailureAlarms from "./RedirectFailureAlarms";

class Alarms extends React.Component {

    state = {
        selectedCase: 1,
    }

    breadCrumbList = [{ link: '/Admin', text: 'Admin' }, { link: '/Admin/Alarms', text: 'Alarms' }];
    campaignReportBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/Alarms', text: 'Redirect Failures', active: true }
    ];

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);
    }

    displayToggleButtons = () => {
        let options = [
            {
                name: "Redirect Failures",
                case: 1,
                action: () => this.setState({ selectedCase: 1 })
            },
        ];

        return options.map((toggle, i) => {
            return (
                <button key={i}
                    className={`btn ${this.state.selectedCase === toggle.case ? "btn-primary btn-lg" : ""}`}
                    onClick={toggle.action}
                >
                    {toggle.name}
                </button>
            )
        })
    }

    render() {
        return (
            <>
                <section>
                    <AccountBanner breadCrumbList={this.state.selectedCase === 1 ? this.campaignReportBreadCrumbList : this.state.selectedCase === 2 ? this.bestSellersReportBreadCrumbList : this.state.selectedCase == 3 ? this.commissionReconcileReportBreadCrumbList : this.breadCrumbList}>
                        <h1 className="mb-4">Alarms</h1>
                        <p className="mb-0">Management of system alarms and alerts</p>
                    </AccountBanner>
                </section>
                <section className="filter-wrapper" >
                    <div className="container">
                        <div className="row py-2 mt-0">
                            <div className="col-12 d-flex justify-content-between">
                                <div className="filter-block d-flex">
                                    {this.displayToggleButtons()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="gray-bg full-height pt-1">
                    <RedirectFailureAlarms
                        display={this.state.selectedCase === 1}
                        loggedInUser={this.props.loggedInUser}
                    />
                </section>
            </>
        )
    }
}

export default Alarms;
import React from "react";
import { getRequest } from "sharedUtils/httpUtils";
import MUIDataTable from "mui-datatables";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import TextField from '@material-ui/core/TextField';
import ActionButton from "components/ActionButton";
import { convertToNumericMonthAndDayAndYearAndTime } from "sharedUtils/timeUtils";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

const { DateTime } = require("luxon");

class CommissionReconcileReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: DateTime.utc().minus({ months: 1 }).startOf('day'),
            endDate: DateTime.utc().startOf('day'),
            minDate: "1753-01-01",
            maxDate: "9999-12-31",
            reports: [],
            partners: [],
            value: [],
            userValue: [],
            reportsLoading: false,
            selected: [],
            userSelected: [],
            options: [],
            userOptions: [],
            loading: false,
            CommissionReconcileReportTable: {
                title: "Commission Reconcile Report",
                columns: [
                    {
                        name: "id",
                        label: "Commission ID",
                        options: {
                            filter: true,
                            sort: true,
                        },
                    },
                    {
                        name: "date",
                        label: "Date",
                        options: {
                            filter: true,
                            sort: true,
                            customBodyRender: (value) => {
                                return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                            }
                        }
                    },
                    {
                        name: "userName",
                        label: "UserName",
                        options: {
                            filter: true,
                            sort: true,
                        },
                    },
                    {
                        name: "email",
                        label: "Email",
                        options: {
                            filter: true,
                            sort: true,
                        },
                    },
                    {
                        name: "amount",
                        label: "Amount",
                        options: {
                            filter: true,
                            sort: true,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return !!(value) ? "$" + value.toFixed(2) : "$0";
                            },
                        },
                    },
                    {
                        name: "merchantId",
                        label: "Merchant ID",
                        options: {
                            filter: true,
                            sort: true,
                        },
                    },
                    {
                        name: "merchant",
                        label: "Merchant",
                        options: {
                            filter: true,
                            sort: true,
                        },
                    },
                ],
                options: {
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                        <div className="d-flex flex-row align-items-center mx-1">
                            <div className="dropdown inline-block mx-1 MuiTypography-subtitle1">
                                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Action
                                </button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                    <button className="dropdown-item" type="button" onClick={() => this.confirmDelete(selectedRows, displayData)}>
                                        Delete Selected Content
                                    </button>
                                    <button className="dropdown-item" type="button" onClick={() => this.confirmPublishSelected(selectedRows, displayData)}>
                                        Publish Selected Content
                                    </button>
                                </div>
                            </div>
                        </div>
                    ),
                    selectableRows: "multiple",
                    sortOrder: {
                        name: 'userId',
                        direction: 'asc'
                    },
                },
                getTheme: () => createTheme({
                    overrides: {
                        MUIDataTableBodyRow: {
                            root: {
                                '&:nth-child(odd)': {
                                    backgroundColor: '#F9F9F9'
                                },
                                cursor: 'pointer'
                            }
                        },
                    }
                })
            },
        };
    }

    breadCrumbList = [{ link: '/Admin', text: 'Admin' }, { link: '/Admin/Analytics/Analytics', text: 'Analytics', active: true }];

    componentDidMount = () => {
        window.scrollTo({ left: 0, top: 0, behavior: 'auto' });
    };

    handleStartDateChange = (event) => {
        const startDateRaw = event.target.value;
        if (startDateRaw.length === 0) return;

        const startDate = DateTime.fromISO(startDateRaw).toUTC().startOf('day');
        if (startDate.isValid) {
            this.setState({ startDate });
        } else {
            console.error("Invalid start date entered:", startDateRaw);
        }
    };

    handleEndDateChange = (event) => {
        const endDateRaw = event.target.value;
        if (endDateRaw.length === 0) return;

        const endDate = DateTime.fromISO(endDateRaw).toUTC().startOf('day');
        if (endDate.isValid) {
            this.setState({ endDate });
        } else {
            console.error("Invalid end date entered:", endDateRaw);
        }
    };

    handleWeekToDate = () => {
        const startDate = DateTime.utc().minus({ weeks: 1 }).startOf('day');
        this.setState({ startDate, endDate: DateTime.utc().startOf('day') });
    };

    handleMonthToDate = () => {
        const startDate = DateTime.utc().minus({ months: 1 }).startOf('day');
        this.setState({ startDate, endDate: DateTime.utc().startOf('day') });
    };

    handleYearToDate = () => {
        const startDate = DateTime.utc().minus({ years: 1 }).startOf('day');
        this.setState({ startDate, endDate: DateTime.utc().startOf('day') });
    };

    handleAllDates = () => {
        const startDate = DateTime.utc(2020, 1, 1).startOf('day'); // January 1, 2020
        const endDate = DateTime.utc().endOf('day'); // Today's date
        this.setState({ startDate, endDate });
    };


    getReports = async () => {
        let url = `api/Account/GetCommissionReconcileReport`;
        const { startDate, endDate } = this.state;
        const offset = DateTime.now().offset;

        if (startDate) url += `?startDate=${startDate.toSQL({ includeOffset: false })}`;
        if (endDate) url += `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        url += `&offset=${offset}`;

        if (this.state.selected.length > 0 && !!(this.state.selected[0].id)) {
            url += `&merchantId=${this.state.selected[0].id}`;
        }

        if (this.state.userSelected.length > 0 && !!(this.state.userSelected[0].id)) {
            url += `&userId=${this.state.userSelected[0].id}`;
        }
        this.setState({ reportsLoading: true });
        const reports = await getRequest(url);
        this.setState({ reports, reportsLoading: false });
    };

    handleGetReport = () => {
        this.getReports();
    };


        fetchUsers = async (query) => {
        this.setState({ isLoading: true });
        let url = `/api/Account/GetUserSuggestionsByUsername?username=${query}`;

        let users = await getRequest(url);
        this.setState({ userOptions: users, isLoading: false });
    }

    setUserSelected = (userSelected) => {
        if (userSelected != null) {
            this.setState({ userSelected });
        }
    };

    setSelected = (selected) => {
        if (selected != null) {
            this.setState({ selected });
        }
    };

    fetchMerchants = async (query) => {
        this.setState({ loading: true });
        const options = await getRequest(`/api/Merchant/MerchantSearchByMerchantName?merchantName=${query}`);
        this.setState({ options, loading: false });
    };

    render() {
        return (
            <div style={{ display: this.props.display ? "" : "none" }}>

                <section className="gray-bg mb-0 pt-2">
                    <div className="container pt-0 pb-0">
                        <div className="search-interest py-1 mx-auto">
                            <div className="search-block d-md-flex justify-content-between">
                                <div className="d-flex" style={{ gap: "5px" }}>
                                    <div style={{ width: "250px" }}>
                                    <AsyncTypeahead
                                        id="merchantSearch"
                                        labelKey="name"
                                        defaultSelected={this.state.value}
                                        clearButton
                                        onSearch={this.fetchMerchants}
                                        onChange={e => this.setSelected(e)}
                                        isLoading={this.state.loading}
                                        options={this.state.options}
                                        placeholder="OPTIONAL search merchants..." />
                                </div>
                                <div style={{ width: "250px" }}>
                                    <AsyncTypeahead
                                        id="userSearch"
                                        labelKey={"userName"}
                                        defaultSelected={this.state.userValue}
                                        clearButton
                                        onSearch={this.fetchUsers}
                                        onChange={selected => this.setUserSelected(selected)}
                                        isLoading={this.state.isLoading}
                                        options={this.state.userOptions} 
                                        placeholder="OPTIONAL userName search..." />
                              </div>  
                                </div>
                                
                                <ActionButton className={`btn btn-primary`}
                                    style={{ height: "38px" }}
                                    onClick={this.handleGetReport}
                                >
                                    Get Report
                                </ActionButton>
                            </div>    
                        </div>
                    </div>
                </section>

                <section className="gray-bg mb-0 pt-2">
                    {
                        !["sm"].includes(this.props.deviceSize) ?
                            <></>
                            :
                            <div className="d-flex w-100 justify-content-end pr-2" style={{ fontFamily: "'Archivo', sans-serif" }}>
                                <div onClick={this.handleWeekToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4px 0 0 4px' }}>1w</div>
                                <div onClick={this.handleMonthToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>1m</div>
                                <div onClick={this.handleYearToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>1y</div>
                                {/* <div onClick={this.handleAllDates} style={{ marginLeft: "-2px", cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0 4px 4px 0' }}>all</div> */}
                            </div>
                    }
                    <div className="container pt-0 pb-0">

                        <div className={`mx-0 d-flex justify-content-between pb-4 ${["sm"].includes(this.props.deviceSize) ? "ml-2 mr-2" : "pt-4"} mx-auto`}>

                            <div className={`d-flex ${["sm"].includes(this.props.deviceSize) ? "w-100 pt-3 mx-2" : ""} justify-content-${["sm"].includes(this.props.deviceSize) ? "between" : "start"}`} style={{ gap: ["sm"].includes(this.props.deviceSize) ? "" : "30px", fontFamily: "'Archivo',sans-serif" }}>
                                <p className="ml-3 pt-3 mb-0" style={{ color: "red", display: this.state.dateError ? "" : "none" }}>Start Date must be before the End Date</p>

                                <div className="d-flex flex-column">
                                    <label className="mb-0">Start Date:</label>
                                    <TextField
                                        id="start-date"
                                        type="date"
                                        value={this.state.startDate.toISODate()}
                                        onChange={this.handleStartDateChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            style: { fontFamily: "'Archivo', sans-serif" },
                                        }} />
                                </div>
                                <div className="d-flex flex-column">
                                    <label className="mb-0">End Date:</label>
                                    <TextField
                                        id="end-date"
                                        type="date"
                                        value={this.state.endDate.toISODate()}
                                        onChange={this.handleEndDateChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            style: { fontFamily: "'Archivo', sans-serif" },
                                        }}
                                    />
                                </div>
                            </div>
                            {
                                ["sm"].includes(this.props.deviceSize) ?
                                    <></>
                                    :
                                    <div className="d-flex" style={{ fontFamily: "'Archivo', sans-serif" }}>
                                        <div onClick={this.handleWeekToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4px 0 0 4px' }}>1w</div>
                                        <div onClick={this.handleMonthToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>1m</div>
                                        <div onClick={this.handleYearToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>1y</div>
                                        {/* <div onClick={this.handleAllDates} style={{ marginLeft: "-2px", cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0 4px 4px 0' }}>all</div> */}
                                    </div>
                            }

                        </div>
                    </div>

                </section>

                <section>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <ThemeProvider theme={this.state.CommissionReconcileReportTable.getTheme()}>
                                    <MUIDataTable
                                        title={this.state.CommissionReconcileReportTable.title}
                                        data={this.state.reports}
                                        columns={this.state.CommissionReconcileReportTable.columns}
                                        options={this.state.CommissionReconcileReportTable.options}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default CommissionReconcileReport;
